
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

import { useRoute } from 'vue-router'

import { i18nextPromise } from './i18n.js'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  async setup() {
    await i18nextPromise
    return {}
  },
  mounted(){
    const route = useRoute()
    if(localStorage.getItem("already_used") == "yes" && this.$route.query.shared != "true"){
      this.$router.push({ path: 'home'})
    }
  }
});
