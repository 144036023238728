
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, triangle, home } from 'ionicons/icons';

import { AdMob, BannerAdPluginEvents } from '@capacitor-community/admob';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  data(){
    return {
      appMargin: 0,
    }
  },
  setup() {
    return {
      ellipse, 
      square, 
      triangle,
      home
    }
  },
  mounted(){
    AdMob.addListener(BannerAdPluginEvents.SizeChanged, (info: any) => {
      this.appMargin = parseInt(info.height, 10);
      if (this.appMargin > 0) {
        const HTMLElement_ionrouteroutlet = document.querySelector('ion-router-outlet');
        if(HTMLElement_ionrouteroutlet != null){
          HTMLElement_ionrouteroutlet.style.marginBottom = this.appMargin + 'px';
        }
      }
    });
  }
});
