import { createApp } from 'vue'
import App from './Suspenser.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import i18n from './i18n'

/* Theme variables */
import './theme/variables.css';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import axios from 'axios'
import * as ads from './ads_engine'

localStorage.removeItem("ads_isRussia")



export async function initialize(): Promise<void> {

  function initCloudMessages(){
    console.log('Initializing HomePage');
  
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
  
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          console.log('Push registration success, token: ' + token.value);
        }
      );
  
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );
  
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );
  
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
        }
      );
  }

  setTimeout(() => {
    initCloudMessages();
  }, 100000);
}

initialize();

// CODE BELOW CUTTED DOWN FROM INITIALIZATION FUNCTION

//   function init_adsService(){
//     let isRussia = false;
//     let isDataFetched = false;
  
//     async function initnow(){
//       localStorage.setItem("ads_isRussia", isRussia + "")
//       if (isRussia) {
//         console.log("Loading this way: Russian... Provider: YandexAds (?mediation)...")
//         await ads.yandexinit();
//       } else {
//         console.log("Loading an alternative: non-Russian... Provider: Admob (?mediation)...")
//         await ads.admobinit();
//       }
//     }
    
//     async function ipapidata(server){
//       switch (server) {
//         case "1":
//           await axios.get("http://ip-api.com/json").then(response => {
//               console.info(response)
//               if (response["data"]["countryCode"] == "RU") {
//                 isRussia = true;
//                 isDataFetched = true;
//               } else {
//                 isRussia = false;
//                 isDataFetched = true;
//               }
//             }).catch(e => {
//               console.log('Submit Fail', e)
//           })
//           if (isDataFetched == false) {
//             ipapidata("2");
//           }else{
//             initnow();
//           }
//           break;
//         case "2":
//           await axios.get("http://ip-api.io/json/").then(response => {
//             console.info(response)
//             if (response["data"]["country_code"] == "RU") {
//               isRussia = true;
//               isDataFetched = true;
//             } else {
//               isRussia = false;
//               isDataFetched = true;
//             }
//           }).catch(e => {
//             console.log('Submit Fail', e)
//           });
//           if (isDataFetched == false) {
//             isRussia = true;
//             initnow()
//           }else{
//             initnow()
//           }
//           break;
//         default:
//           break;
//       }
//     }
  
//     ipapidata("1");
//   }
  
//   init_adsService();


//   initCloudMessages();
// }


// initialize();

import * as adsEngine from "./ads_engine"
adsEngine.prepareAdsService();

const app = i18n(createApp(App))
  .use(IonicVue)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});